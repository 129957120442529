import { useSelector } from 'react-redux';

import { ObjectInterface } from '@interfaces';

import { matchPermission } from '@helpers/permission';

import { SubNavItemInterface } from './../components/Nav/SubNavItem';

export const useFilterItemsPermission = (items?: SubNavItemInterface[]): SubNavItemInterface[] => {
  const permissions: ObjectInterface = useSelector((state: any) => state.auth?.user?.permissions || {});
  const res: SubNavItemInterface[] = [];
  items &&
    items.forEach((item: SubNavItemInterface) => {
      const { permission: permissionId } = item;
      if (!permissionId) {
        res.push(item);
        return true;
      }
      const allowed = matchPermission(permissionId, permissions);
      if (allowed) {
        res.push(item);
        return true;
      }
      return false;
    });
  return res;
};

export default useFilterItemsPermission;
